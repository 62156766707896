/// <reference path="../../../../../../Content/angular/angular.d.ts" />
/// <reference path="../../../../../../Scripts/typings/moment/moment.d.ts" />
/// <reference path="../../../../../lodash/lodash.d.ts" />
/// <reference path="../../../models.d.ts" />

angular.module("Zotec.PatientExperience.BillPay")
    .controller("zpBillPayController", ["$scope", "Restangular", "$state", "$window", "$siteSettings", "$guarantor", "$pdf", "$promptModal", "$q",
        "$paymentFailureModal", "$emailReceiptModal", "$loadPaymentModuleLibrary", "$donationModal", "$patientExperienceSettings", "$loadCheckoutJSLibrary",
        "PaymentModuleFormService", "$sessionStorage",
        function ($scope, Restangular: restangular.IService, $state, $window, $siteSettings: zotec.patientExperience.Services.SiteSettingsService,
            $guarantor: zotec.patientExperience.Services.GuarantorService, $pdf: zotec.patientExperience.Services.PdfService, $promptModal,
            $q: angular.IQService, $paymentFailureModal, $emailReceiptModal, $loadPaymentModuleLibrary, $donationModal,
            $patientExperienceSettings: zotec.patientExperience.Services.IPatientExperienceSettings, $loadCheckoutJSLibrary,
            $paymentModuleFormService: zotec.patientExperience.Services.IPaymentModuleFormService, $sessionStorage) {

            let ctrl = this;

            this.internalRequest = Restangular.one("internalnetwork").get().$object;

            this.LOADING_STATE = 0;
            this.NORMAL_STATE = 1;
            this.ERROR_STATE = 2;

            this.currentState = this.LOADING_STATE;

            this.AMOUNT_PAGE = 0;
            this.DONATE_PAGE = 1;
            this.METHOD_PAGE = 2;
            this.SUCCESS_PAGE = 3;
            this.pageCount = 4;

            this.currentPage = this.AMOUNT_PAGE;
            this.donationToggle = false;

            this.$onInit = () => {
                $scope.$watch(() => ctrl.paymentAmount, (newVal, oldVal) => {
                    if (newVal != undefined && newVal != oldVal && ctrl.paymentModuleFormOptions !== undefined) {
                        ctrl.paymentModuleFormOptions.paymentAmount = newVal;
                    }
                });
            }

            this.applePayCallbacks = {
                onError: (error) => {
                    ctrl.removeApplePayFromPaymentMethods();
                    ctrl.resetSelectedPaymentMethod();
                },
                onApproval: (response) => {},
                onDeclined: (response) => ctrl.removeApplePayFromPaymentMethods(),
                onCancelled: () => {
                    ctrl.removeApplePayFromPaymentMethods();
                    ctrl.resetSelectedPaymentMethod();
                },
            } as zotec.patientExperience.payments.ApplePayTransactionCallbacks;

            this.creditCardFormHandle = null;
            this.echeckFormHandle = null;
            this.isApplePayActive = false;

            this.isLoading = () => {
                return this.currentState == this.LOADING_STATE;
            }

            this.isInErrorState = () => {
                return this.currentState == this.ERROR_STATE;
            }

            this.isInNormalState = () => {
                return this.currentState == this.NORMAL_STATE;
            }

            this.shouldShowAmountPage = () => {
                return this.isInNormalState() && this.currentPage == this.AMOUNT_PAGE;
            }

            this.shouldShowDonatePage = () => {
                if (!this.isInNormalState()) {
                    return false;
                } else {
                    if (this.currentPage != this.DONATE_PAGE) {
                        return false;
                    } else {
                        if (!this.donationToggle) {
                            this.moveForward();
                            return false;
                        }
                        if (this.donationShown) {
                            this.moveForward();
                            return false;
                        }
                        if (this.paymentAmount != this.guarantorBalance) {
                            this.moveForward();
                            return false;
                        } else {
                            return true;
                        }
                    }
                }
            }

            this.shouldShowMethodPage = () => {
                return this.isInNormalState() && this.currentPage == this.METHOD_PAGE;
            }

            this.shouldShowSuccessPage = () => {
                return this.isInNormalState() && this.currentPage == this.SUCCESS_PAGE;
            }

            this.moveForward = () => {
                if (this.currentPage + 1 >= this.pageCount) {
                    $state.reload();
                    return;
                }

                this.currentPage += 1;
                if (this.METHOD_PAGE == this.currentPage) {
                    this.currentState = this.NORMAL_STATE;
                }
            }

            this.moveBack = () => {
                if (this.currentPage - 1 < 0) {
                    return;
                }
                this.currentPage -= 1;
                if (this.AMOUNT_PAGE == this.currentPage) {
                    this.resetTransactionInfo();
                }
            }

            this.moveBackToAmountPage = () => {
                this.currentPage = this.AMOUNT_PAGE;
                this.resetTransactionInfo();
            }

            this.donationAmount = null;
            this.otherDonationAmount = null;
            this.showOtherDonation = false;
            this.showDonateDialog = false;
            this.modal = null;
            this.donationShown = false;

            this.donationClicked = (amount: Number) => {

                var donation = null;

                // other donation
                if (amount == null) {

                    // user enters 0 or negative for other donation
                    if (this.otherDonationAmount <= 0.0) {
                        this.donationAmount = this.otherDonationAmount < 0.0 ? 0.0 : this.otherDonationAmount;
                        this.showDonateDialog = true;

                        // enter 0 to data base
                        donation = {
                            "ClientId": $siteSettings.clientId,
                            "ChargeIds": this.openChargeIds,
                            "DonationAmount": this.donationAmount,
                            "FinalIntent": false,
                        };
                        $guarantor.restangularized().all("Donation").post(donation);
                        this.moveForward();
                        return;
                    }

                    // user enters normal other amount
                    this.donationAmount = this.otherDonationAmount > 100.0 ? 100.0 : this.otherDonationAmount;
                    this.otherDonationAmount = null;
                    this.showOtherDonation = false;
                    this.showDonateDialog = true;
                    this.modal = $donationModal(this.donationAmount, this.dismissDonationDialog);
                    return;
                }
                this.donationAmount = amount;

                // normal donation
                if (amount != -1 && amount != 0.0) {
                    this.otherDonationAmount = null;
                    this.showOtherDonation = false;
                    this.showDonateDialog = true;
                    this.modal = $donationModal(this.donationAmount, this.dismissDonationDialog);
                }
                // if user skips donation
                else if (amount == 0.0) {
                    donation = {
                        "ClientId": $siteSettings.clientId,
                        "ChargeIds": this.openChargeIds,
                        "DonationAmount": 0,
                        "FinalIntent": false,
                    };
                    $guarantor.restangularized().all("Donation").post(donation);
                    this.donationAmount = 0;
                    this.moveForward();
                }
                // show other donation dialog
                else {
                    this.donationAmount = 0.0;
                    this.showOtherDonation = true;
                }
            }

            this.dismissDonationDialog = () => {
                this.donationShown = true;
                var donation = {
                    "ClientId": $siteSettings.clientId,
                    "ChargeIds": this.openChargeIds,
                    "DonationAmount": this.donationAmount,
                    "FinalIntent": false,
                };
                $guarantor.restangularized().all("Donation").post(donation);
            }

            this.getTermsOfUseUrl = () => {
                var returnUrl = $siteSettings.getTermsOfUseUrl();
                return returnUrl;
            }

            this.applePayInitErrorHandler = (message: string) => {
                this.logError(message);
            }

            this.guarantorBalance = 0.00;
            this.paymentAmount = 0.00;
            this.sessionData = null;
            this.pmCharges = [];

            this.goToErrorState = (logErrorMessage: string) => {
                if (!this.isInErrorState()) {
                    this.currentState = this.ERROR_STATE;
                    $paymentFailureModal();
                }
                this.logError(logErrorMessage);
                console.log(logErrorMessage);
            }

            this.logError = (message: string) => {
                $siteSettings.billingGroupRestangularized().all("PostProcess").all("Log")
                    .post({ logMessage: message, guarantorId: $guarantor.guarantorId, transactionId: this.transactionId, consoleErrors: this.loadingLibraryErrors });
            }

            this.splunkErrorLog = console.error.bind(console);
            this.loadingLibraryErrors = [];
            console.error = function () {
                var argumentsList = [];
                for (var i = 0; i < arguments.length; i++) {
                    argumentsList.push(arguments[i]);
                }
                this.loadingLibraryErrors.push(argumentsList);
                this.splunkErrorLog.apply(console, arguments);
            }.bind(this);

            let donationTogglePromise = Restangular.one("ApplicationFeatures").get({
                featureName: $patientExperienceSettings.features.enableDonationIntent,
                featureVersion: "1.0.0",
                dataSourceId: $siteSettings.dataSourceId,
                clientId: $siteSettings.clientId,
            }).then((response: string) => {
                this.donationToggle = response === 'True';
            }, () => {
                this.donationToggle = false;
            });

            let paymentModuleLibraryPromise = $siteSettings.billingGroupRestangularized().all("PaymentModule").one("Library").get()
                .then((response: string) => {
                    $loadPaymentModuleLibrary(response);
                }, () => {
                    this.goToErrorState("Bill pay was unable to load the Payment Module.");
                });

            let sessionDataPromise = $siteSettings.billingGroupRestangularized().all("PaymentModule").one("SessionData").get()
                .then((data: string) => {
                    this.sessionData = data;
                }, () => {
                    this.goToErrorState("Bill pay was unable to load the Payment Module.");
                });

            let chargesBalancePromise = $guarantor.restangularized()
                .all("charges").one("balance").get().then((balance: zotec.patientExperience.guarantorCommunication.GuarantorBalance) => {
                    this.guarantorBalance = balance.balance.toFixed(2);
                    this.paymentAmount = balance.balance.toFixed(2);
                    this.openChargeIds = [];
                    
                    _.forEach(balance.openCharges, (charge: zotec.patientExperience.guarantorCommunication.Charge) => {
                        this.openChargeIds.push(charge.chargeId);
                        this.pmCharges.push({
                            id: charge.chargeId,
                            discount: 0,
                            discountCode: "",
                            isCredit: false,
                            paidInFull: true,
                            paymentAmount: charge.balance,
                            originalAmount: charge.baseFee,
                        });

                    });
                    
                }, () => {
                    this.goToErrorState("Bill pay was unable to retrieve guarantor balance.");
                });

            this.hasPaymentPlan = null as boolean;

            let paymentsPlanPromise = $guarantor.restangularized()
                .all("PaymentPlan").one("HasPlan").get().then((hasPlan: boolean) => {
                    this.hasPaymentPlan = hasPlan;
                }, () => {
                    this.goToErrorState("Bill pay was unable to retrieve payment plan information for the user.");
                });

            this.minPaymentAmountActual = 0;
            this.minPaymentAmount = 0;
            this.maxNumberOfPayments = 0;
            this.availablePaymentMethods = [];
            this.payOnlyPhone = null as string;
            this.canPayOnline = null as boolean;
            this.acceptPaymentPlan = false;

            let billingGroupClientInfoPromise = $siteSettings.billingGroupRestangularized().one("clientinfo").get()
                .then((info: zotec.patientExperience.client.ClientInfo) => {
                    if (info.acceptCreditCard) {
                        this.availablePaymentMethods.push({
                            name: "Credit Card",
                            key: "cc"
                        });
                    }
                    if (info.acceptElectronicCheck) {
                        this.availablePaymentMethods.push({
                            name: "Electronic Check",
                            key: "echk"
                        });
                    }
                    this.canPayOnline = this.availablePaymentMethods.length > 0;
                    this.payOnlyPhone = info.payOnlyPhone;
                    this.minPaymentAmountActual = info.minPaymentAmount;
                    this.minPaymentAmount = info.minPaymentAmount;
                    this.acceptPaymentPlan = info.acceptPaymentPlan;
                    this.maxNumberOfPayments = info.maxNumberOfPayments;
                }, () => {
                    this.goToErrorState("Bill pay was unable to retrieve client information for user.");
                });

            $q.all([paymentModuleLibraryPromise,
                sessionDataPromise,
                chargesBalancePromise,
                paymentsPlanPromise,
                billingGroupClientInfoPromise,
                donationTogglePromise
            ]).finally(() => {
                this.postInitHandler();
            });

            this.transactionId = null as string;
            this.acceptedCreditCards = [];
            this.selectedPaymentMethod = "";

            this.ccFormWatchUnregister = null;
            this.ecFormWatchUnregister = null;

            this.resetTransactionInfo = () => {
                this.transactionId = null as string;
                this.acceptedCreditCards = [];
                this.selectedPaymentMethod = "";

                if (null != this.ccFormWatchUnregister) {
                    this.ccFormWatchUnregister();
                    this.ccFormWatchUnregister = null;
                }

                if (null != this.ecFormWatchUnregister) {
                    this.ecFormWatchUnregister();
                    this.ecFormWatchUnregister = null;
                }
                $scope.$digest;
            }

            this.onPaymentMethodChanged = (form) => {
                var paymentModuleApi: zotec.patientExperience.payments.IPaymentModuleApiV3 = $window["PaymentModule"];
                this.disposeForms();
                this.isApplePayActive = this.selectedPaymentMethod === 'apple';

                switch (this.selectedPaymentMethod) {
                    case "cc":                        
                        const ccForm = paymentModuleApi.InitCreditCardForm("payment-module-credit-card-container", { includeSwipe: false, includeNotes: false });
                        this.creditCardFormHandle = $paymentModuleFormService.configureForm(ccForm, this.paymentModuleFormOptions);
                        this.ccFormWatchUnregister = $scope.$watch(() => form.$valid, (newVal, oldVal) => {
                            if (newVal != undefined && newVal != oldVal) {
                                if (newVal) {
                                    this.creditCardFormHandle.Enable();
                                } else {
                                    this.creditCardFormHandle.Disable();
                                }
                            }
                        });

                        break;
                    case "echk":
                        const ecForm = paymentModuleApi.InitECheckForm("payment-module-electronic-check-container", { includeNotes: false });
                        this.echeckFormHandle = $paymentModuleFormService.configureForm(ecForm, this.paymentModuleFormOptions);
                        this.ecFormWatchUnregister = $scope.$watch(() => form.$valid, (newVal, oldVal) => {
                            if (newVal != undefined && newVal != oldVal) {
                                if (newVal) {
                                    this.echeckFormHandle.Enable();
                                } else {
                                    this.echeckFormHandle.Disable();
                                }
                            }
                        });

                        break;
                    default:
                        break;
                }
            }

            this.disposeForms = () => {
                if (this.ccFormWatchUnregister) {
                    this.ccFormWatchUnregister();
                    this.ccFormWatchUnregister = null;
                }

                if (this.creditCardFormHandle) {
                    this.creditCardFormHandle.Dispose();
                    this.creditCardFormHandle = null;
                }

                if (this.ecFormWatchUnregister) {
                    this.ecFormWatchUnregister();
                    this.ecFormWatchUnregister = null;
                }

                if (this.echeckFormHandle) {
                    this.echeckFormHandle.Dispose();
                    this.echeckFormHandle = null;
                }
            }

            this.successfulPayment = (null as PaymentSummary);

            this.handlePaymentCompletion = (response: zotec.patientExperience.payments.WebHookResponse) => {
                if (this.donationAmount != null && this.donationToggle && this.paymentAmount >= this.guarantorBalance) {
                    var donation = {
                        "ClientId": $siteSettings.clientId,
                        "ChargeIds": this.openChargeIds,
                        "DonationAmount": this.donationAmount,
                        "FinalIntent": true,
                    };
                    $guarantor.restangularized().all("Donation").post(donation);
                }
                var paymentId = parseInt(response.webhookResponse);
                this.guarantorBalance = (this.guarantorBalance - this.paymentAmount).toFixed(2);
                this.successfulPayment = new PaymentSummary("Payment Successful", this.paymentAmount, this.guarantorBalance, response.webhookResponse, paymentId, response.maskedAccountNumber);
                this.paymentAmount = this.guarantorBalance;
                this.setSuccessButtonColClass();
                this.moveForward();
                this.setupFeedback();
                $scope.$digest();
            }

            this.successButtonColClass = "col-md-6";

            this.setSuccessButtonColClass = () => {
                if (this.successfulPayment.remainingBalance > 0) {
                    if (this.hasPaymentPlan) {
                        this.successButtonColClass = "col-md-4";
                    } else {
                        this.successButtonColClass = "col-md-3";
                    }
                }
            }

            this.useFeedbackOverride = false;
            this.feedbackUrl = null as string;
            this.feedbackPromptButtonText = null as string;

            this.setupFeedback = () => {
                this.useFeedbackOverride = $siteSettings.feedbackUrl && $siteSettings.feedbackUrl.length > 0;
                this.feedbackUrl = $siteSettings.feedbackUrl || $state.href("entity.surveyexp");
                this.feedbackPromptButtonText = $siteSettings.feedbackPromptButtonText || "Leave Feedback";

                this.promptFeedbackIfEnabled();
            }

            this.promptFeedbackIfEnabled = () => {
                if ($siteSettings.feedbackPromptAfterPayment) {
                    const feedbackPromptText = $siteSettings.feedbackPromptText || "Tell us about your billing experience by leaving feedback.";
                    console.log(
                        "Toggles for the prompt modal are as follows. SendPatientSurveyUponPaymentAndNoFeedback: " +
                        $siteSettings.sendPatientSurveyUponPaymentAndNoFeedback +
                        " and fromDirectLink: " +
                        $sessionStorage.fromDirectLink);
                    $promptModal("Payment successful", feedbackPromptText, this.feedbackPromptButtonText, "No thanks", () => {
                        window.open(this.feedbackUrl);
                    }, () => {
                        if ($siteSettings.sendPatientSurveyUponPaymentAndNoFeedback && $sessionStorage.fromDirectLink) {
                            var surveyRequest = {
                                AppName: "PXP",
                                PersonId: $guarantor.guarantorId,
                                DataSourceId: $siteSettings.dataSourceId,
                                ClientId: $siteSettings.clientId,
                                BillingGroupId: $siteSettings.billingGroupId
                            }
                            console.log("adding survey request function to the prompt modal: ", surveyRequest);
                            Restangular.all("PaymentReceived").post(surveyRequest);
                        }
                    });
                }
            }

            this.printReceipt = () => {
                $pdf.open($siteSettings.billingGroupRestangularized().getRestangularUrl() + "/receipt/download", { paymentId: this.successfulPayment.paymentId, isPrePay: false, isTOSReceipt: false }, "Generating receipt...", null);
            }

            this.showEmailReceiptModal = () => {
                $emailReceiptModal(this.successfulPayment.paymentId);
            }

            this.shouldShowPaymentPlanButton = () => {
                this.calculatePaymentPlanOptions();
                return   this.acceptPaymentPlan && !this.hasPaymentPlan && this.successfulPayment.remainingBalance >= this.minPaymentAmount && this.hasCalculatedPPOptions;
            }

            this.calculatePaymentPlanOptions = () => {
                if (this.successfulPayment.remainingBalance && this.minPaymentAmount && this.maxNumberOfPayments) {
                    this.clientMinPaymentAmount = Math.max(Math.ceil(this.successfulPayment.remainingBalance / this.maxNumberOfPayments * 100) / 100, Math.min(this.minPaymentAmount, this.successfulPayment.remainingBalance));
                    let maxPaymentsWithMinimumAmount = Math.floor(this.successfulPayment.remainingBalance / this.clientMinPaymentAmount);
                    this.hasCalculatedPPOptions = maxPaymentsWithMinimumAmount > 1;
                }
            }

            this.openSurvey = () => {
                $window.open($state.href("entity.surveyexp", "_blank"));
            }

            this.postInitHandler = () => {
                if (!this.isInErrorState()) {
                    this.minPaymentAmountActual = Math.min(this.minPaymentAmountActual, this.guarantorBalance);
                    this.currentState = this.NORMAL_STATE;

                    this.paymentModuleApi = $window["PaymentModule"];

                    this.paymentModuleFormOptions = {
                        chargeType: "PatientPostPay",
                        appName: "PXP",
                        entityId: "",
                        locationId: "",
                        sessionData: ctrl.sessionData,
                        charges: ctrl.pmCharges,
                        paymentAmount: ctrl.paymentAmount,
                        onSuccessCallback: ctrl.handlePaymentCompletion,
                        webhookUrl: ctrl.buildWebhookUrl(),
                    } as zotec.patientExperience.PaymentModuleFormOptions;
                }
            }

            this.buildWebhookUrl = (): string => {
                return `${window.location.origin}/api/datasources/${$siteSettings.dataSourceId}/clients/${$siteSettings.clientId}/billinggroups/${$siteSettings.billingGroupId}/PostProcess/Payment/${$guarantor.guarantorId}`;
            }

            this.removeApplePayFromPaymentMethods = () => {
                this.availablePaymentMethods = this.availablePaymentMethods.filter((x) => x.key !== "apple");
            }

            this.resetSelectedPaymentMethod = () => {
                this.selectedPaymentMethod = "";
                $scope.$apply();
            }

            this.applePayClickHandler = () => {
                this.availablePaymentMethods.push({
                    name: "Apple Pay",
                    key: "apple"
                });

                this.selectedPaymentMethod = "apple";
                this.onPaymentMethodChanged(null);
            }
        }]);

class PaymentSummary {
    amountPaid: number;
    remainingBalance: number;
    confirmationNumber: string;
    paymentId: number;
    paymentMethod: string;
    message: string;
    constructor(message: string, payment: number, remaining: number, confirmation: string, pId: number, paymentMethod: string) {
        this.message = message;
        this.amountPaid = payment;
        this.remainingBalance = remaining;
        this.confirmationNumber = confirmation;
        this.paymentId = pId;
        this.paymentMethod = paymentMethod;
    }
}
